import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBarcode } from "react-barcodes";
import { isMobile } from "helpers";

import { Modal } from "components/Modal";
import "./Barcode.css";

import square from "assets/images/barcode_square.svg";
import circle from "assets/images/barcode_circle.svg";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useConfig } from "state";

interface Props {
  onRequestClose?: () => void;
  employeeId?: string;
  name?: string;
  barcode?: string;
  isOpen: boolean;
}

export const BadgeBarcode: FC<Props> = ({ employeeId, name, barcode, isOpen, onRequestClose }) => {
  const { t } = useTranslation();
  const config = useConfig();
  const isVE = config.connectorType === "VE";

  return (
    <Modal
      actions={null}
      isOpen={isOpen}
      maxHeight={false}
      size="normal"
      title={t("BADGE")}
      onRequestClose={onRequestClose}
    >
      <div style={{ minHeight: isMobile() ? 600 : 300 }}>
        <div className={isMobile() ? "rectangle-box-mobile" : "rectangle-box"}>
          <img alt="" className="square" src={square} />
          <div>
            {isVE ? (
              <span className="employee-id">{t("Employee ID: {employeeId}", { employeeId })}</span>
            ) : null}
            <br />
            <span className="name">{name}</span>{" "}
          </div>
          <img alt="" className="circle" src={circle} />
          {/* VE Barcode always shows the code while in V7 the barcode has an eye toggle to hide/show the barcode value. */}
          {isVE ? <BarcodeVE code={barcode} /> : <Barcode code={barcode} />}
        </div>
      </div>
    </Modal>
  );
};

function BarcodeVE(props: { code?: string }) {
  const { inputRef } = useBarcode({
    options: {
      background: "#FFFFFF",
    },
    value: props.code || "",
  });
  return <svg ref={inputRef} className="rectangle-barcode-ve" />;
}

function Barcode(props: { code?: string }) {
  const { inputRef } = useBarcode({
    options: {
      background: "#FFFFFF",
      displayValue: false,
    },
    value: props.code || "",
  });
  const [isMasked, setIsMasked] = useState(true);
  return (
    <div className="rectangle-barcode">
      <svg ref={inputRef} className="barcode" />
      <div className="barcode-text-wrapper">
        <span>{isMasked ? "●".repeat(props.code?.length || 0) : props.code}</span>
        {isMasked ? (
          <EyeInvisibleOutlined className="eye-icon" onClick={() => setIsMasked((bool) => !bool)} />
        ) : (
          <EyeOutlined className="eye-icon" onClick={() => setIsMasked((bool) => !bool)} />
        )}
      </div>
    </div>
  );
}
