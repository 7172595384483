import { Col } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Account } from "api";
import { Row } from "components/Grid";
import { Card } from "components/Card";
import { Button } from "components/Button";
import { formatPrice } from "helpers/formatPrice";
import { Text, Title } from "components/Typography";

import { AutoloadSubscribed } from "../AutoloadSubscribed";
import { isAcctExpired } from "pages/WalletDashboard/components/AccountsList";
import { useConfig } from "state";
import { components } from "api-schema";

interface Props {
  onAutoload?: () => void;
  onLoad?: () => void;
  account: Account;
}

export const ReloadCard: FC<Props> = ({ account, onLoad, onAutoload }) => {
  const { t } = useTranslation();
  const isAcctDisabled = account.accountStatus === "SUSPENDED" || isAcctExpired(account);
  const config: {
    amsSite?: components["schemas"]["AmsSite"];
  } = useConfig();

  const renderDisabledTag = (type: "suspend" | "expire") => {
    return (
      <Button block disabled type="primary" onClick={onLoad}>
        {type === "suspend" ? t("ACCOUNT_SUSPENDED") : t("ACCOUNT_EXPIRED")}
      </Button>
    );
  };
  const pointsLabel = config?.amsSite?.siteTheme?.labels?.["label.points.title.text"];

  return (
    <Card elevated className="mb-4">
      <Title level={5}>{account.accountName}</Title>
      <div className="d-flex justify-content-center align-items-center flex-column p-3">
        <Text strong data-testid={"balance"} size="large">
          {t("BALANCE")}
          {":  "}
          {account.accountType !== "MEAL_PLAN_ACCOUNT" && account.accountType !== "POINTS_ACCOUNT"
            ? formatPrice(account.balance)
            : account.accountType === "MEAL_PLAN_ACCOUNT"
            ? t("MEAL_PLURAL", {
                balance: account.balance,
              })
            : !!pointsLabel
            ? `${account.balance} ${pointsLabel}`
            : t("POINTS_PLURAL", {
                balance: account.balance,
              })}
        </Text>

        {account.loadAccountAllowed && (
          <Row className="w-100 mb-3 mt-4">
            <Col
              lg={{ offset: 8, span: 8 }}
              md={{ offset: 6, span: 12 }}
              xs={{ offset: 0, span: 24 }}
            >
              {isAcctExpired(account) ? (
                renderDisabledTag("expire")
              ) : account.accountStatus === "SUSPENDED" ? (
                renderDisabledTag("suspend")
              ) : (
                <Button block type="primary" onClick={onLoad}>
                  {t("LOAD_ACCOUNT")}
                </Button>
              )}
            </Col>
          </Row>
        )}

        {account.accountType !== "POINTS_ACCOUNT" && (
          <Row className="w-100 ">
            <Col
              lg={{ offset: 8, span: 8 }}
              md={{ offset: 6, span: 12 }}
              xs={{ offset: 0, span: 24 }}
            >
              {account.autoloadDetails?.subscriptionStatus === true ? (
                <AutoloadSubscribed
                  min={account.autoloadDetails?.thresholdAmount || 0}
                  value={account.autoloadDetails?.loadAmount || 0}
                  onClick={!isAcctDisabled ? onAutoload : undefined}
                />
              ) : account.loadAccountAllowed ? (
                <Button block disabled={isAcctDisabled} type="link" onClick={onAutoload}>
                  {t("AUTOLOAD")}
                </Button>
              ) : null}
            </Col>
          </Row>
        )}
      </div>
    </Card>
  );
};
