import { Col, DatePicker, List } from "antd";
import React, { FC, useMemo } from "react";

import { Account, Api } from "api";
import { Row } from "components/Grid";
import { Card } from "components/Card";
import { Icon } from "components/Icon";
import { Select } from "components/Select";
import { formatDate } from "helpers/formatDate";
import { Text, Title } from "components/Typography";

import { PrintPrice } from "../PrintPrice";

import styles from "./transactionsList.module.css";
import { useTranslation } from "react-i18next";
import { ITransactionFilter } from "../TransactionsTable";
import { DEFAULT_PAGE_SIZE } from "../../../../helpers";
import moment from "moment/moment";
import { SwapRightOutlined } from "@ant-design/icons";
import { components } from "api-schema";
import { useConfig } from "state";

interface Props {
  onSelect: (val: Api["TransSummary"]) => void;
  onChangeFilter: (val: ITransactionFilter) => void;
  data: Api["TransSummary"][];
  accounts: Account[];
  isLoading: boolean;
  filter: ITransactionFilter;
  totalLength: number;
}

export const TransactionsList: FC<Props> = ({
  onChangeFilter,
  isLoading,
  onSelect,
  accounts,
  filter,
  data,
  totalLength,
}) => {
  const { t } = useTranslation();
  const filterOptions = useMemo(
    () =>
      [{ label: React.createElement("span", {}, t("ALL_ACCOUNTS")), value: "all" }].concat(
        accounts.map((i) => ({
          label: React.createElement(
            "div",
            {},
            <span>
              {i.accountName}
              <span className={styles.filterAccountId}>({i.accountDisplayId ?? i.accountId})</span>
            </span>
          ),
          value: i.accountId || "",
        }))
      ),
    [accounts, t]
  );
  const config: {
    amsSite?: components["schemas"]["AmsSite"];
  } = useConfig();

  const pointsLabel = config?.amsSite?.siteTheme?.labels?.["label.points.title.text"];

  return (
    <>
      <Row className="mb-4">
        <Col span={24}>
          <Row>
            <div>{t("ACCOUNTS")}</div>
          </Row>
          <Row>
            <Select
              // @ts-ignore TODO: ...
              defaultValue={filter.filterAccountId}
              key={filter.filterAccountId}
              // @ts-ignore TODO: ... antd type problem
              options={filterOptions}
              size="large"
              value={filter.filterAccountId}
              onSelect={(val) => {
                onChangeFilter({ filterAccountId: val, page: 0 });
              }}
            />
          </Row>
        </Col>
      </Row>
      <Row className={"mb-4"}>
        <Col span={24}>
          <Row>
            <Col className={"w-50"}>
              <div>{t("START_DATE")}</div>
            </Col>
            <Col className={"w-50"}>
              <div>{t("END_DATE")}</div>
            </Col>
          </Row>
          <Row>
            <DatePicker
              allowClear={false}
              className={"w-50"}
              disabled={isLoading}
              disabledDate={(current) => current && current > moment().endOf("day")}
              picker={"date"}
              suffixIcon={<SwapRightOutlined />}
              value={moment(filter.filterStartTime)}
              onChange={(date) => {
                onChangeFilter({
                  filterStartTime: date?.toISOString(),
                  page: 0,
                });
              }}
            />
            <DatePicker
              allowClear={false}
              className={"w-50"}
              disabled={isLoading}
              disabledDate={(current) =>
                current &&
                (current > moment().endOf("day") ||
                  current < moment(filter.filterStartTime).endOf("day"))
              }
              picker={"date"}
              value={moment(filter.filterEndTime)}
              onChange={(date) => {
                onChangeFilter({
                  filterEndTime: date?.toISOString(),
                  page: 0,
                });
              }}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card elevated className={styles.listWrapper} padding="none">
            <List
              dataSource={data}
              loading={isLoading}
              pagination={
                filter.page !== undefined
                  ? {
                      current: filter.page + 1,
                      onChange: (page) => {
                        onChangeFilter({ page: page - 1 });
                      },
                      pageSize: DEFAULT_PAGE_SIZE,
                      showSizeChanger: false,
                      style: { marginBottom: "10px", marginRight: "10px" },
                      total: totalLength,
                    }
                  : undefined
              }
              renderItem={(item) => {
                const accountType = getAccountType(item.accountId, accounts);
                return (
                  <List.Item
                    className={styles.item}
                    key={item.id}
                    onClick={item.transId ? () => onSelect(item) : undefined}
                  >
                    <List.Item.Meta
                      avatar={
                        <Icon className="mr-2" name={typeToIcon[transactionType(item)]} size={28} />
                      }
                      title={
                        <>
                          <Text ellipsis className="d-block mb-1" color="primary">
                            {item.accountId}
                          </Text>
                          <Title ellipsis className="d-block mt-0 mb-1" level={4}>
                            {item.transDate ? formatDate(item.transDate, "longDate") : null}
                          </Title>
                          <Text ellipsis>{item.terminal}</Text>
                        </>
                      }
                    />
                    <>
                      {accountType !== "MEAL_PLAN_ACCOUNT" && accountType !== "POINTS_ACCOUNT" ? (
                        <PrintPrice value={item.amount} />
                      ) : accountType === "MEAL_PLAN_ACCOUNT" ? (
                        t("MEAL_NUMBER_PLURAL", {
                          balance: item.amount,
                        })
                      ) : !!pointsLabel ? (
                        `${item.amount} ${pointsLabel}`
                      ) : (
                        t("POINTS_PLURAL", {
                          balance: item.amount,
                        })
                      )}
                    </>
                  </List.Item>
                );
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

/**
 * Map transaction type to icon
 */
const typeToIcon = {
  CREDIT: "sync",
  DEBIT: "tag",
  SHARED: "team",
} as const;

function transactionType(transaction: Api["TransSummary"]): keyof typeof typeToIcon {
  const { type, sharedTrans } = transaction;
  if (sharedTrans) {
    return "SHARED";
  }
  return type;
}

function getAccountType(accountId: string, accounts: Account[]) {
  return accounts.find((i) => {
    return i.accountId === accountId;
  })?.accountType;
}
